<template>
  <div>
    <networks></networks>
  </div>
</template>

<script>
  import Networks from '../../components/Networks/Networks';

  export default {
    name: 'Networks',
    components: {
      networks: Networks
    },
    data() {
      return {

      };
    }
  };
</script>
